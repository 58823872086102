import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Input from "../input";
import { apiMessage, Options, validationMessage } from "../../utils/constants";
import Select from "../select/Select";
import { getLabel } from "../../utils/helper";
import CrossIcon from "@mui/icons-material/Clear";
import { isDataLoading } from "../../redux/dataSlice";
import { useDispatch } from "react-redux";
import { mutationAPI } from "../../api/services";
import { TextArea } from "..";
import logoProfile from "../../assets/profile.png"
import { Download, Edit } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { errorAvailable, errorStatus } from "../../redux/errorSlice";
import { useState } from "react";
import LogoPdf from "../../assets/pdf.png"
import { useLocation } from "react-router-dom";
import { validationSchema } from "../../utils/schema";

export default function CustomModal(props) {
    const fileInputRef = React.useRef(null);

    const [selectedImage, setSelectedImage] = useState("");
    const [error, setError] = useState("");

    const dispatch = useDispatch()
    const location = useLocation();
    const { pathname } = location;
    const { Open, Close, isEdit = false, dataResponse, setResponseData, title, isDisable, ApiData, defaultResponse, modalEdit = false, rowData } = props;
    //Dynamic Modal Ui handling
    console.log({ selectedImage }, { dataResponse })
    const getComponents = (key) => {
        console.log({ key })
        // Ensure key exists in data
        if (!dataResponse.hasOwnProperty(key)) {
            return null;
        }
        const errorMessage = error && error[key];
console.log({errorMessage},{dataResponse})
        if (key === "title" || key === "username" || key === "heading" || key === "value" || key === "email" && !isEdit || key === "password") {
            return (
                <div className="address-edit-wrapper">
                    <Input
                        type={key === "password" ? "password" : "text"}
                        label={getLabel(key)} // Dynamically generate label
                        name={key} // Use the key as the name
                        value={dataResponse[key]} // Access the value of the key (e.g., data.title or data.years)
                        onChange={(e) => {
                            setError("")

                            const value = e.target.value;

                            // Prevent input if it's only whitespace
                            if (!value.trim() && value.length > 0) {
                                return;
                            }

                            setResponseData((prevState) => ({

                                ...prevState,
                                [key]: e.target.value, // Update the specific key

                            }))
                        }
                        }

                    />
                    {errorMessage && <div className="err">{errorMessage}</div>} {/* Render error message */}

                </div>
            )
        } else if (key === "years") {

            return (
                <div>
                    <Select
                        label="Years"
                        value={dataResponse[key]}
                        name={key}
                        onChange={(e) => {
                            // const value = e.target.value;

                            // // Prevent input if it's only whitespace
                            // if (!value.trim() && value.length > 0) {
                            //     return;
                            // }
                            setResponseData({ ...dataResponse, [key]: e.target.value })

                        }

                        }
                        data={Options}
                    />

                    {errorMessage && <div className="err">{errorMessage}</div>} {/* Render error message */}
                </div>)
        } else if (key === "description") {
            return (
                <>
                    <TextArea
                        type="text"
                        label="Description"
                        name="name"
                        value={dataResponse[key]}
                        onChange={(e) =>
                            setResponseData((prevState) => ({
                                ...prevState,
                                [key]: e.target.value, // Update the specific key
                            }))
                        } />
                    {errorMessage && <div className="err">{errorMessage}</div>} {/* Render error message */}
                </>
            )
        } else if (key === "url") {
            return (
                <div>
                    <div className="image-container">
                        {!selectedImage && !dataResponse?.url ?
                            <div >
                                <img src={logoProfile}
                                    className="image-logo-css"
                                />
                            </div>
                            :
                            <img
                                src={selectedImage || dataResponse?.url || selectedImage?.s3Url}
                                alt={`profile`}
                                className="img-css"

                            />
                        }

                        <input
                            ref={fileInputRef}

                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            style={{ display: 'none' }}
                        />
                        <div onClick={handleButtonClick}
                            className="image-position"
                        >
                            <Edit style={{ height: ".833vw", width: ".833vw", color: "#86868B" }} />
                        </div>

                    </div>
                    {errorMessage && <div className="err">{errorMessage}</div>} {/* Render error message */}
                </div>
            )
        }
    };


    const handleButtonClick = () => {
        fileInputRef.current.click();
    };    //Submit Handler is here

    const handleImageChange = async (e) => {

        const file = e.target.files[0];

        console.log({ file })
        if (file) {
            const imageURL = URL.createObjectURL(file); // Create an object URL for the file
            setSelectedImage(imageURL); // Update the state with the image URL
        }

        if (file) {

            const reader = new FileReader();
            reader.onloadend = () => {
                // setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append('files', file);

            try {
                const response = await mutationAPI(
                    "/v1/auth/uploadImage",
                    "POST",
                    formData
                )
                setSelectedImage(response?.data?.data?.[0]?.s3Url)
                setResponseData({ ...dataResponse, ["url"]: response?.data?.data?.[0]?.s3Url })

            }
            catch (err) {

            }
        }
    };
    // console.log({ dataResponse })
    const validateForm = () => {
        let errors = {};

        if (isEdit) {
            delete defaultResponse?.password;

            delete defaultResponse?.email;
        }

        const requiredFields = Object.keys(defaultResponse); // Fields to validate

        for (let field of requiredFields) {
            const schema = validationSchema(field); // Get validation schema for the field
            try {
                schema.validateSync({ [field]: dataResponse[field] }); // Validate the field
            } catch (validationError) {
                errors[field] = validationError.message; // Add error message for the field
            }
        }

        return Object.keys(errors).length === 0 ? null : errors; // Return errors or null if no errors
    };


    const submitHandler = async () => {
        const errors = validateForm();
        if (errors) {
            setError(errors); // Store errors in state to display on UI
            return;
        }

        try {
            const payload = {};

            // Build the payload dynamically
            if (dataResponse?._id) payload._id = dataResponse._id;
            if (dataResponse?.username) payload.userName = dataResponse.username;
            if (dataResponse?.title) payload.title = dataResponse.title;
            if (dataResponse?.heading) payload.heading = dataResponse.heading;
            if (dataResponse?.description) payload.description = dataResponse.description;
            if (dataResponse?.years) payload.description = dataResponse.years;

            if (dataResponse?.value) payload.value = dataResponse.value;
            if (dataResponse?.url || selectedImage?.s3Url) payload.url = selectedImage?.s3Url || dataResponse?.url;
            if (dataResponse?.email) payload.email = dataResponse.email;
            if (dataResponse?.password) payload.password = dataResponse.password;

            if (pathname === "/endorsement") {
                payload.userType = "Business";
                payload.isEndorseUser = true;



            }

            // Start loading state
            dispatch(isDataLoading(true));
            // return                  
            // Perform the API call
            const response = await mutationAPI(ApiData?.endPoint, ApiData?.method, payload);
            console.log({ response }, response?.err?.response?.data?.message)

            if (response?.err?.response?.data?.message !==undefined && response?.err?.response?.data?.message !== "") {
                dispatch(errorStatus(apiMessage.FAILURE));

                dispatch(errorAvailable(response?.err?.response?.data?.message || "error"));
                setResponseData(defaultResponse);
                setSelectedImage("")

                Close(!Open);

                return
            }
            if (isEdit) {
                dispatch(errorAvailable("Edited successfully"));

            } else {
                dispatch(errorAvailable(response?.err?.response?.data?.message || "created successfully"));
            }
            dispatch(errorStatus(apiMessage.SUCCESS))
            // Update state on success
            setResponseData(defaultResponse);
            setSelectedImage("")
            ApiData?.getApi();
            Close(!Open);

        } catch (error) {
            console.error("API error:", error);
            setError(validationMessage.error_occured);
            dispatch(errorAvailable(validationMessage.error_occured));
            dispatch(errorStatus(apiMessage.FAILURE))
        } finally {
            // Always end the loading state
            dispatch(isDataLoading(false));
        }
    };


    const handleClose = () => {
        Close(!Open)
        setResponseData(defaultResponse)
        setError("")
    }
    const downloadPDF = async (pdfUrl, fileName) => {
        try {
            // Fetch the PDF file as a blob
            const response = await fetch(pdfUrl);
            const blob = await response.blob();

            // Create an Object URL for the blob
            const url = URL.createObjectURL(blob);

            // Use the 'navigator' API to trigger a file download
            const fileNameToSave = fileName || "download.pdf";
            const link = document.createElement("a");

            // Set the link properties
            link.href = url;
            link.download = fileNameToSave;

            // Programmatically click the link
            link.click();

            // Release the object URL
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the PDF:", error);
        }
    };
    const downloadImage = async (imageUrl, fileName) => {
        try {
            // Fetch the image data
            const response = await fetch(imageUrl);

            // Check if the response is successful
            if (!response.ok) {
                throw new Error(`Failed to fetch image: ${response.statusText}`);
            }

            // Convert the response to a blob
            const blob = await response.blob();

            // Create a temporary object URL
            const objectURL = URL.createObjectURL(blob);

            // Create an anchor element to initiate the download
            const anchor = document.createElement('a');
            anchor.href = objectURL;
            anchor.download = fileName; // Provide a name for the downloaded file
            document.body.appendChild(anchor); // Append the anchor to the DOM
            anchor.click(); // Trigger a click event to start the download

            // Cleanup
            document.body.removeChild(anchor); // Remove the anchor from the DOM
            URL.revokeObjectURL(objectURL); // Release memory associated with the object URL
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };




    // Usage

    // Usage




    const validIndustryLength = rowData?.industries?.filter(industry => typeof industry === "object" && industry?.title?.trim() !== "").length;
    const validInterestLength = rowData?.interests?.filter(interest => typeof interest === "object" && interest?.value?.trim() !== "").length;
    const validDocLength = rowData?.docs?.filter(foc => typeof foc === "object" && foc?.s3Url?.trim() !== "").length;
    //    interests
    return (
        <React.Fragment>
            <Dialog
                className="dialog-content-box"
                open={Open}
                onClose={Close}
                fullWidth
                maxWidth="sm"
                disableBackdropClick={true}
            >
                {!modalEdit &&
                    <DialogTitle className="dialog_title">{!isEdit ? `Create ${title}` : `Edit ${title}`}

                        {/* <CrossIcon onClick={() => Close()}
                        /> */}
                    </DialogTitle>}
                <DialogContent>
                    {modalEdit ?
                        <>
                            <div className="crossIcon">     <CrossIcon onClick={() => Close()} /></div>
                            <div className="main-div">

                                <div >
                                    <p className="heading-p">Name</p>
                                    <p className="subHead-p">{rowData?.userName}</p></div>
                                <div>
                                    <p className="heading-p">Email</p>
                                    <p className="subHead-p">{rowData?.email}</p></div>

                                {validIndustryLength > 0 &&
                                    <div>
                                        <p className="heading-p">Industry</p>
                                        {rowData?.industries?.map((item) => {
                                            return (
                                                <p className="subHead-p">{item?.title}</p>
                                            )
                                        })}</div>}


                                {validInterestLength > 0 &&
                                    <div >

                                        <p className="heading-p">Interest</p>
                                        <div className="interset-div">
                                            {rowData?.interests?.map((item) => {
                                                return (
                                                    <p className="subHead-interests">{item?.value}</p>
                                                )
                                            })}</div>
                                    </div>}
                                {validDocLength > 0 &&
                                    <div >

                                        <p className="heading-p">Documents</p>
                                        <div className="documentHead">
                                            {rowData?.docs?.map((item) => {
                                                return (item?.fileType === "image") ?

                                                    <div className="pdf-card-container">
                                                        <div className="pdf-card">
                                                            {/* Three dots in the top-right corner */}
                                                            <div className="card-options">
                                                                <Download onClick={() => downloadImage(item?.s3Url, item?.fileName)} />

                                                            </div>


                                                            {/* PDF Image */}
                                                            <div className="pdf-images">
                                                                <img src={item?.s3Url} alt="PDF Icon" />
                                                            </div>
                                                        </div>
                                                    </div> : (item?.fileType === "pdf") ?
                                                        <div className="pdf-card-container">
                                                            <div className="pdf-card">
                                                                {/* Three dots in the top-right corner */}
                                                                <div className="card-options">
                                                                    <Download onClick={() => downloadPDF(item?.s3Url, item?.fileName)} />

                                                                </div>


                                                                {/* PDF Image */}
                                                                <div className="pdf-image">
                                                                    <img src={LogoPdf} alt="PDF Icon" />
                                                                </div>
                                                            </div>
                                                        </div> : ""


                                            })}
                                        </div>

                                    </div>}

                            </div>
                        </> :
                        <div className="div_modal" >
                            {Object.keys(dataResponse)
                                ?.map((key) => (
                                    <React.Fragment key={key}>{getComponents(key)}</React.Fragment>
                                ))
                            }


                            {/* {error &&
    Object.entries(error).map(([field, message]) => (
        <div key={field} className="err">
            {message}
        </div>
    ))
} */}
                        </div>}
                </DialogContent>
                {!modalEdit &&
                    <DialogActions>
                        <Button
                            onClick={submitHandler}
                            className="submit-button"
                        // sx={{
                        //     textTransform: "capitalize",
                        //     fontWeight: 500,

                        //     background: isDisable ? "grey" : "#c9ff00",
                        //     cursor: isDisable ? "not-allowed" : "pointer",
                        //     color: "#fff !important",
                        //     "&:hover": {
                        //         background: isDisable ? "grey" : "#c9ff00",
                        //     },
                        // }}
                        >

                            <DoneIcon />
                        </Button>
                        <Button
                            onClick={() => handleClose()}
                            className="close-button"

                        >
                            <CloseIcon />
                        </Button>

                    </DialogActions>}

            </Dialog>

        </React.Fragment>
    );
}
