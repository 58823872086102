import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Skeleton } from "..";

const Chart = ({ chartData, initialChartLoad }) => {
  // Custom colors for each series
  const seriesColors = {
    normalUsers: '#c9ff00a6',
    expertUsers: '#212b44',
    businessUsers: '#000000'
  };

  const getMonth = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const chartXAxis = () => {
    if (chartData?.length && "week" in chartData?.[0]) {
      return chartData?.map((value) => `week ${value?.week}`);
    } else if (chartData?.length && "year" in chartData?.[0]) {
      return chartData?.map(
        ({ year, month }) => `${[getMonth[month]]}-${year}`
      );
    } else {
      return ["Value"];
    }
  };

  const getSeriesData = () => {
    if (initialChartLoad) {
      return [
        {
          name: 'Normal Users',
          data: [1, 2, 4],
          color: seriesColors.normalUsers
        },
        {
          name: 'Expert Users',
          data: [2, 3, 5],
          color: seriesColors.expertUsers
        },
        {
          name: 'Business Users',
          data: [3, 4, 6],
          color: seriesColors.businessUsers
        }
      ];
    }

    return [
      {
        name: 'Normal Users',
        data: chartData?.map(item => item.normalCount),
        color: seriesColors.normalUsers
      },
      {
        name: 'Expert Users',
        data: chartData?.map(item => item.expertCount),
        color: seriesColors.expertUsers
      },
      {
        name: 'Business Users',
        data: chartData?.map(item => item.businessCount),
        color: seriesColors.businessUsers
      }
    ];
  };

  const options = {
    chart: {
      type: "column",
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        depth: 50,
        viewDistance: 25,
      },
      events: {
        render: function() {
          if (!chartData?.length && !initialChartLoad) {
            const x = this.plotWidth / 2 + this.plotLeft;
            const y = this.plotHeight / 2 + this.plotTop;
            
            if (!this.noDataLabel) {
              this.noDataLabel = this.renderer
                .label('No Data Available', x, y, null, null, null, true, false, 'noDataLabel')
                .css({
                  fontSize: '16px',
                  fontWeight: '500',
                  fontFamily: '"Public Sans", sans-serif',
                  color: '#666'
                })
                .align({ align: 'center', verticalAlign: 'middle' }, false, 'none')
                .add();
            }
          } else if (this.noDataLabel) {
            this.noDataLabel.destroy();
            delete this.noDataLabel;
          }
        }
      }
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: initialChartLoad
        ? ["week 1", "week 2", "week 3"]
        : chartXAxis(),
      labels: {
        style: {
          fontSize: '16px',
          fontFamily: '"Public Sans", sans-serif',
          fontWeight: 600
        }
      }
    },
    yAxis: {
      title: {
        text: 'Number of Users',
        style: {
          fontFamily: '"Public Sans", sans-serif'
        }
      },
      labels: {
        style: {
          fontSize: '16px',
          fontFamily: '"Public Sans", sans-serif',
          fontWeight: 600
        }
      }
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}'
    },
    title: {
      text: chartData?.length
        ? "USER STATISTICS"
        : initialChartLoad
          ? ""
          : "",
      align: "left",
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        fontFamily: '"Public Sans", sans-serif"',
        color: "rgb(38, 38, 38)",
        cursor: "pointer",
      },
      margin: 30,
    },
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'bottom',
      itemStyle: {
        fontFamily: '"Public Sans", sans-serif'
      }
    },
    plotOptions: {
      column: {
        depth: 25,
        grouping: true,
        groupPadding: 0.2
      },
      series: {
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    colors: [seriesColors.normalUsers, seriesColors.expertUsers, seriesColors.businessUsers],
    series: !chartData?.length && !initialChartLoad ? [] : getSeriesData()
  };

  return (
    <>
      {false ? (
        <div>
          <Skeleton.Card
            width={"50px"}
            height={"200px"}
            value={4}
            borderRadius="4px"
          />
        </div>
      ) : (
        <div className="w-full min-h-[400px] flex items-center justify-center">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      )}
    </>
  );
};

export default Chart;