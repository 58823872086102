

// Icons.js
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { DeleteOutline, Reviews } from "@mui/icons-material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PreviewIcon from '@mui/icons-material/Preview';

export {
    HomeIcon,
    SettingsIcon,
    SearchIcon,
    ClearIcon,
    EditIcon,
    DeleteIcon,
    AddIcon,
    SaveIcon,
    DeleteOutline,
    Reviews,
    PreviewIcon,AccountCircleIcon,
    VisibilityIcon
};


export const Options = [
  { value: "1-2 years", key: "1-2" },
  {
    value: "2-4 years",
    key: "2-4",
  },
  { value: "4-6 years", key: "4-6" },
  {
    value: "6-8 years",
    key: "6-8",
  },
  { value: "10+", key: "10+" },
];
export const title = {
  delete: "Are you sure you want to delete?",
  publish: "Do you want to publish this Advertisement?",
  wrong: "Something went wrong...!",
  update:""
};


export const apiMessage={
SUCCESS:"success",
SOMETHING_WENT_WRONG:"Something went wrong.",
ERROR:"error",
FAILURE:"failure",
EMAIL_SENT:"Email sent successfully! Please check your email.",
SUCESS_LOGIN:"Password reset successfully! Please login."
}

export const validationMessage={
 email: "Kindly enter email.",
 password:"Kindly enter password.",
 passwordDontMatch:"Passwords do not match.",

newPassword :"Kindly enter New password.",
confirmPassword:"Kindly enter Confirm password.",
error_occured:"An error occurred while submitting the form. Please try again."
}


