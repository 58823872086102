import React from "react";
import { TablePagination } from "@mui/material";

const Index = ({ rowsPerPage, page, setPage, setRowsPerPage, url }) => {
  console.log({ url }, "opop");

  const handleChangePage = (event, newPage) => {
    console.log("New Page:", newPage); // Logs the next page index
    setPage(newPage); // Update the page state
  };

  const handleChangeRowsPerPage = async (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page after rows per page change
    console.log("Rows per page changed:", newRowsPerPage);
  };

  return (
    <TablePagination
    className="custom-pagination"
      component="div"
      count={100} // Total number of rows (for testing purposes)
      page={page} // Current page index
      onPageChange={handleChangePage} // Callback for page change
      rowsPerPage={rowsPerPage} // Rows per page
      onRowsPerPageChange={handleChangeRowsPerPage} // Callback for rows per page change
    />
  );
};

export default Index;
