// focusSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isdataFocus: false,
};

const focusSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    isDataFocus: (state, action) => {
      state.isdataFocus = action.payload;
    },
  },
});

export const { isDataFocus } = focusSlice.actions; // Correctly export actions
export default focusSlice.reducer; // Correctly export reducer
