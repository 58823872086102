import React from "react";
import logo from "../../assets/no_data.svg";
const NoData = ({title}) => {
 
  return (
    <div className="no-content">
      <img src={logo} alt="No Data" />
      <span>{`No data Found!`}</span>
    </div>
  );
};

export default NoData;
