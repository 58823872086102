import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import MuiSelect from "@mui/material/Select";
import { Box, FormControl, InputLabel } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
const Select = (props) => {
  const { value, onChange, label, name,disabled, data, className, multiple, selectMultipleVal } = props;
  console.log({value})
  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  return (
    <Box className="select_container">
      <FormControl fullWidth  className={className}>
        <InputLabel
          id={`${
            multiple
              ? "demo-multiple-checkbox-label"
              : "demo-simple-select-label"
          }`}
        >
          {label}
        </InputLabel>
        <MuiSelect
          labelId={`${
            multiple
              ? "demo-multiple-checkbox-label"
              : "demo-simple-select-helper-label"
          }`}
          id="demo-simple-select-helper"
          value={value}
          onChange={onChange}
         
          multiple={multiple}
          disabled={disabled}
          label={label}
          name={name}
          renderValue={multiple && ((selected) => (
            <Box className="muiSelect" >
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          ))}
          MenuProps={multiple && MenuProps}
        >
          {data?.map((val) => {
            if (typeof val !== "object") {
              return (
                <MenuItem key={val} value={val}>
              <Checkbox checked={selectMultipleVal?.indexOf(val) > -1} />
              <ListItemText primary={val} />
            </MenuItem>
              );
            } else {
              return (
                <MenuItem key={val.key} value={val.key}>
                  {val.value}
                </MenuItem>
              );
            }
          })}
        </MuiSelect>
      </FormControl>
    </Box>
  );
};

export default Select;
