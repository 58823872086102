import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Input, Notification } from "../components";
import Logo from "../assets/reffyy_full_navy.png";

import ErrorField from "../components/error-field";

import { errorAvailable, errorClose, errorStatus } from "../redux/errorSlice";
import { useValidationErrors } from "../utils/useErrors";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { apiMessage, validationMessage } from "../utils/constants";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const [errors, setErrors, handleValidationErrors] = useValidationErrors();
  const errorDetails = useSelector((state) => state?.error);
  const token = queryParams.get("token") || localStorage.getItem("ACCESS_TOKEN");
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (formData?.password && formData?.newPassword && formData?.confirmPassword) {
      // Check if newPassword and confirmPassword match
      if (formData?.newPassword !== formData?.confirmPassword) {
        setErrors({
          newPassword: validationMessage.passwordDontMatch,
          confirmPassword: validationMessage.passwordDontMatch,
        });
        return; // Exit the function if passwords do not match
      }
      const payload= {
        currentPassword: formData?.password,
        newPassword: formData?.newPassword,
      }
if(!queryParams.get("token")){
  payload.type ="changePassword"
}
    
      try {
        // const token = localStorage?.getItem("ACCESS_TOKEN");
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/admin/resetPassword`,
        payload ,
          {
            headers: {
              Authorization: `${token}`, // Add "Bearer " prefix
            },
          }
        );
  
        if (response.status === 200) {
          dispatch(errorAvailable(apiMessage.SUCESS_LOGIN));
          dispatch(errorStatus(apiMessage.SUCCESS));
          localStorage.clear();
          navigate("/login", { state: true });
        }
        
      
        if (response.status === 422) {
          dispatch(errorAvailable(response?.err?.response?.data?.message));
        }
      } catch (error) {
        console.log({ error });
          dispatch(
            errorAvailable(
              error?.response?.data?.message || apiMessage.SOMETHING_WENT_WRONG
            )
          );
          dispatch(
            errorStatus( apiMessage.FAILURE
            )
          );
        
        handleValidationErrors(error?.message);
      }
    } else {
      setErrors({
        password: formData?.password ? "" : validationMessage.validationMessage,
        newPassword: formData?.newPassword ? "" : validationMessage.newPassword,
        confirmPassword: formData?.confirmPassword ? "" : validationMessage.confirmPassword,
      });
    }
  };
  
  const handleFormChange = ({ key, value }) => {
    setErrors({});
    setFormData({ ...formData, [key]: value });
  };

  return (
    <div
      className={`lui-auth-wrapper `}
    >
      <div className="lui-auth-container">
        <div className="lui-auth-banner">
          <img src={Logo} alt="logo" />
        </div>
        <div className="lui-auth-heading">
          <h5>Reset Password!</h5>
        </div>
        <div className="lui-auth-form">
          <form autoComplete="off">

            <Input
              placeholder="Current Password"
              type="password"
              label={"Current Password"}
              name={"password"}
              value={

                formData?.["password"]
              }
              onChange={(e) =>
                handleFormChange({
                  key: "password",
                  value: e.target.value,
                })
              }
            />
            <ErrorField error={errors?.password} />

            <Input
              placeholder="New Password"
              type="password"
              label="New Password"
              name="newPassword"
              value={formData?.newPassword}
              onChange={(e) =>
                handleFormChange({
                  key: "newPassword",
                  value: e.target.value,
                })
              }
            />
            <ErrorField error={errors?.newPassword} />

            <Input
              placeholder="Confirm Password"
              type="password"
              label="Confirm Password"
              name="confirmPassword"
              value={formData?.confirmPassword}
              onChange={(e) =>
                handleFormChange({
                  key: "confirmPassword",
                  value: e.target.value,
                })
              }
            />
            <ErrorField error={errors?.confirmPassword} />
            <Button
              variant="primary"
              onClick={handleSubmit}
              className="login-btn"
            >
              {"Reset"}
            </Button>
          </form>
        </div>
      </div>
      <Notification
        value={errorDetails?.errorName}
        display={errorDetails?.errorDisplay}
        onClose={() => {
          dispatch(errorClose(false));
        }}
      />
    </div>
  );
};

export default ResetPassword;
