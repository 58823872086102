import * as React from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CrossIcon from "@mui/icons-material/Clear";

export default function PopUp(props) {
  const { isOpen, close, submitHandler, title, children,expert } = props;
  console.log({expert})
  return (
    <>
      <Dialog open={isOpen} className="dialog-content-box" >
        {title ? (
          <DialogTitle
          sx={{width:"450px"}}
          >
            <div style={{display:"flex",justifyContent:"space-between",flexDirection:"row",width:"100%"}}>
            {title}
            {expert ?    <CrossIcon sx={{position:"absolute",top:"10px",right:"10px"}}  onClick={ close}
                        />:"" }</div>
          </DialogTitle>
        ) : null}
        {!title ? <DialogContent>{children}</DialogContent> : null}
        <DialogActions >
          <Button
            onClick={()=>{submitHandler(true)}}
            className="submit-button"
          >
           {expert ? "Approve": <DoneIcon />}
          </Button>
          <Button
            onClick={expert ?()=>{submitHandler(false)}: close}
            className="close-button"
          >
          {expert? "Decline":  <CloseIcon />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
