import React, { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Rating } from "@mui/material";
import GIF from "../../assets/no_data_found.gif"
import IMG from "../../assets/avatar.png"
import { ADMIN_EXPERT_VERIFICATION_UPDATE } from "../../api/constants";
import { isDataLoading } from "../../redux/dataSlice";
import { mutationAPI } from "../../api/services";
import { useDispatch } from "react-redux";
import { errorAvailable, errorStatus } from "../../redux/errorSlice";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
function Cards(props) {
  const dispatch = useDispatch()
  const { data, review, fetchList, setCallapi, onClose } = props

  console.log(data?.industries,"industries")

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Handle next image navigation
  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % data.media.length);
  };

  // Handle previous image navigation
  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + data.media.length) % data.media.length);
  };
  // const handleUpdateVerification = async (data, status) => {
  //   try {
  //     dispatch(isDataLoading(true));

  //     const responseUpdate = await mutationAPI(`${ADMIN_EXPERT_VERIFICATION_UPDATE}`, "POST", {
  //       "email": data?.email,
  //       "isCertiApproved": status,
  //       "isActionPerformed": true
  //     });
  //     dispatch(errorAvailable("Success"));
  //     dispatch(errorStatus("successfully approved"))
  //     setCallapi(true)
  //     onClose()
  //     dispatch(isDataLoading(false));

  //   } catch (error) {
  //     dispatch(isDataLoading(false));

  //   } finally {
  //     dispatch(isDataLoading(false))
  //   }
  // }
  // console.log({ data })
  // const validIndustryLength = data?.industries?.filter(industry => typeof industry === "object"&& industry?.title?.trim() !== "").length ;
  // const validInterestLength = data?.interests?.filter(interest =>typeof interest === "object"&& interest?.value?.trim() !== "").length;
  // const validDocLength = data?.docs?.filter(foc => typeof foc === "object" && foc?.s3Url?.trim() !== "").length;
  return (
    <Box className="box-css" padding={2}>

      {review ?
        <Card sx={{ maxWidth: 300, position: "relative", boxShadow: "0px 4px 10px rgba(33, 43, 68, 0.7)" }}>
          {/* Display current image */}

          {data.media[currentImageIndex] === undefined ?


            <img className="img-css-modal"
              src={GIF} />
            : <div className="data-media" >{data.media[currentImageIndex]}</div>

          }
          {/* Card content */}

          {data.media[currentImageIndex] !== undefined && data.media?.length > 0 &&
          <CardContent sx={{ background: "rgb(33, 43, 68)", color: "white" }}>
            <Typography variant="h6" component="div">
              {data?.name || "--"}
            </Typography>
            <Typography variant="body2" color="white">
              {data?.review || ""}
            </Typography>
            <Typography variant="body2" color="white">
              {data?.date || ""}

            </Typography>
            <Rating
              name="simple-controlled"
              value={"1"}
              contentEditable={false}
              sx={{
                '& .MuiRating-iconHover': {
                  color: 'inherit', // Prevent hover color change
                },
                '& .MuiRating-iconFocus': {
                  color: 'inherit', // Prevent focus color change
                },
                '& .MuiRating-iconActive': {
                  color: 'inherit', // Prevent active color change
                },
                "& .MuiRating-iconEmpty": {
                  color: "white", // Empty stars
                },
        
              }}
            />
          </CardContent>}
          {/* Navigation arrows */}
          {data.media[currentImageIndex] !== undefined && data.media?.length > 0 &&
            <>
              <IconButton
                sx={{
                  position: "absolute",
                  top: "40%",
                  left: 8,
                  transform: "translateY(-50%)",
                  backgroundColor: "rgba(255,255,255,0.7)",
                }}
                onClick={handlePrevImage}
              >
                <ArrowBackIosIcon sx={{
                  width: "20px",
                }} />
              </IconButton>
              <IconButton
                sx={{

                  position: "absolute",
                  top: "40%",
                  right: 8,
                  transform: "translateY(-50%)",
                  backgroundColor: "rgba(255,255,255,0.7)",
                }}
                onClick={handleNextImage}
              >
                <ArrowForwardIosIcon sx={{
                  width: "20px",
                }} />
              </IconButton></>}
        </Card>
        :
        null
//         <Card sx={{
//           maxWidth: 300, position: "relative", boxShadow: "0px 4px 10px rgba(33, 43, 68, 0.7)", height: "300px", width: "250px", border: "5px solid rgb(226 255 116)", borderRadius: "20px", position: "relative",

//           overflow: "hidden"
//         }}>
//           <Box className="semi-circle">

//             <img src={IMG} alt="Circular Image" class="circular-image" />  </Box>
//           <div className="div-card-container">
//             <div
//               className="div-card">
//                 <div style={{display:"flex"}}>
//               <p className="text" >{data?.userName}</p>
//               {data?.isCertiApproved && data?.isActionPerformed ?
//                 <CheckCircleOutline sx={{ color: "green" }} />
//                 : !data?.isCertiApproved && data?.isActionPerformed ?
//                   <CancelOutlined sx={{ color: "red" }} /> : ""}</div>
//              <Accordion
//   sx={{
//     width: "100%", // Make the accordion full width

//     margin: 0, // Remove margin
//     padding: 0, // Remove padding
//     border: "none", // Add grey border
//     borderRadius: "4px", // Optional: round the corners
//     "& .MuiAccordionSummary-root": {
//       boxShadow:"none",
//       padding: 0, // Remove padding from the summary
//       margin: 0, // Remove margin from the summary
//       minHeight: "auto", // Adjust minimum height
//       "& .MuiAccordionSummary-content": {
//         margin: 0, // Remove inner margin
//       },
//     },
//     "& .MuiAccordionDetails-root": {
//       padding: 0, // Remove padding from the details
//       transition: "all 0.3s ease", // Smooth opening transition
//     },
//   }}
// >
//   <AccordionSummary
//     expandIcon={<ExpandMoreIcon />}
//     aria-controls="panel1-content"
//     id="panel1-header"
//   >
//     <Typography component="span">Interest</Typography>
//   </AccordionSummary>
//   <AccordionDetails>
//     {validInterestLength > 0 &&
//       data?.interests?.map((item) => (
//         <p className="subHead-interests">{item?.value}</p>
//       ))}
//   </AccordionDetails>
// </Accordion>

//               <Accordion      sx={{
//                     width: "100%", // Make the accordion full width

//     margin: 0, // Remove margin
//     padding: 0, // Remove padding
//     "& .MuiAccordionSummary-root": {
//       padding: 0, // Remove padding from the summary
//       margin: 0, // Remove margin from the summary
//       minHeight: "auto", // Adjust minimum height
      
//       "& .MuiAccordionSummary-content": {
//         margin: 0, // Remove inner margin
//       },
//     },
//     "& .MuiAccordionDetails-root": {
//       padding: 0, // Remove padding from the details
//     },
//   }}
// >
//                 <AccordionSummary
//                   expandIcon={<ExpandMoreIcon />}
//                   aria-controls="panel2-content"
//                   id="panel2-header"
//                 >
//                   <Typography component="span">Industry</Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                 {validIndustryLength >0   &&

//                 data?.industries?.map((item) => {
//                                             return (
//                                                 <p className="subHead-p">{item?.title}</p>
//                                             )
//                                         })}
//                 </AccordionDetails>
//               </Accordion>
//             </div>
//           </div>
//         </Card>
        
        }

    </Box>



  );
}

export default Cards;
