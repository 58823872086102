import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { getIcon } from "../../utils/helper";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isDataFocus, isdataFocus } from "../../redux/focusSlice";
const Input = ({
  type,
  onChange,
  label,
  value,
  accept,
  mediaAttribute,
  name,
  placeholder = "",
  readOnly,
  disabled,
  customClass,
  className
}) => {

  const dispatch=useDispatch()
  const [showPassword, setShowPassword] = useState(false);
  const { pathname } = useLocation();

  const getValue = () => {
     if (pathname?.includes("login")) {
      return value ? value : null;
    }
    else {
      return value
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !showPassword);
  };
  const inputRef = useRef(null);

  const handleIconClick = () => {
    // Set focus to the input field when the icon is clicked
    if (inputRef.current) {
      inputRef.current.focus();
    }
    // Additional logic (e.g., toggling password visibility) if needed
    if (togglePasswordVisibility) {
      togglePasswordVisibility();
    }
  };
  const handleFocus = () => {
    dispatch(isDataFocus(true));
  };

  const handleBlur = () => {
    dispatch(isDataFocus(false));

  };
  console.log({showPassword})
  return (
    <div className={`input_container ${className}`}>
      <TextField
 onFocus={handleFocus}
 onBlur={handleBlur}      disabled={disabled}
        fullWidth
        id={name}
        type={showPassword ? "text" : type}
        onChange={onChange}
        value={getValue()}
        name={name}
        required={showPassword ? true : true}
        autoComplete="off"
        label={label}
        inputRef={inputRef} // Assign ref to the input field

        placeholder={placeholder}
        InputProps={{
          inputProps: { accept: accept, readOnly: readOnly },
          endAdornment: (
            <InputAdornment position="end">
                 <span onClick={handleIconClick}  className="span_input">
                {getIcon(
                  label ?? mediaAttribute,
                  showPassword,
                  type === "file" ? onChange : togglePasswordVisibility
                )}
              </span>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default Input;