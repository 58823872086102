import * as Yup from "yup";


export const emailSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address"
    )
    .max(50, "Email must be at most 50 characters")
    .required("Kindly enter email"),
});
export const validationSchema = (prop) => {
  if (prop === "title") {
    return Yup.object().shape({
      [prop]: Yup.string()
        .required("Title is required.")
        .max(30, "Title must be at most 30 characters."),
    });
  } else if (prop === "url") {
    return Yup.object().shape({
      [prop]: Yup.string().required("Image is required."),
    });
  } else {
    return Yup.object().shape({
      [prop]: Yup.string().required(`${prop} is required.`),
    });
  }
};

export const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password should be at least 8 characters long")
    .max(16, "Password should not be greater than 16 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).+$/,
      "Password must be at least 6 characters and include at least one uppercase letter, one lowercase letter, and one special character",

    )
    .required("Kindly enter password"),
});


export const LastNameSchema = Yup.object().shape({
  lastName: Yup.string().required("LastName is required"),
});

export const imageValidationSchema = Yup.object().shape({
  media: Yup.mixed()
    .test("value-check", "Image is required", function (value) {
      if (!value) {
        return false;
      }

      if (typeof value === "string") {
        return true;
      }

      if (value instanceof File) {
        return true;
      }

      return false;
    })
    .test(
      "file-size",
      "Image size must be between 50KB and 5MB",
      function (value) {
        if (value instanceof File) {
          const fileSizeInBytes = value.size;
          const fileSizeInKB = fileSizeInBytes / 1024;
          return fileSizeInKB >= 50 && fileSizeInKB <= 5120;
        }
        return true;
      }
    ),
});
export const VideoSchema = Yup.object().shape({
  video: Yup.mixed()
    .test("value-check", "Video is required", function (value) {
      if (!value) {
        return false;
      }

      return true;
    })
    .test(
      "file-size",
      "Video size must be between 50KB and 40MB",
      function (value) {
        if (value instanceof File) {
          const fileSizeInBytes = value.size;
          const fileSizeInKB = fileSizeInBytes / 1024;
          return fileSizeInKB >= 50 && fileSizeInKB <= 40960;
        }
        return true;
      }
    ),
});



export const titleSchema = (val) => {
  if(val){
    return Yup.object().shape({
      title: Yup.string()
        .required("Title is required")
        .max(val, `Title must be at most ${val} characters`),
    });
  }
  else {
    return Yup.object().shape({
      title: Yup.string()
        .required("Title is required")
        
    });
  }
};

export const descriptionSchema = Yup.object().shape({
  description: Yup.string()
    .required("Description is required")
    .max(500, "Description must be at most 500 characters"),
});
export const descriptionSchemaRequired = Yup.object().shape({
  description: Yup.string()
    .required("Description is required")
});


export const confirmPasswordSchema = Yup.object().shape({
  confirmPassword: Yup.string()
  .required("Confirm Password is required")
  .min(8, "Confirm Password should be at least 8 characters long")
  .max(16, "Confirm Password should not be greater than 16 characters")
  .matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).+$/,
    "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one special character"
  )
  .test('passwords-match', 'Password and confirm password should match', function (value) {
    return this.parent.password === value;
  }),
});
export const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
  .required("Password is required")
  .min(8, "Password should be at least 8 characters long")
  .max(16, "Confirm Password should not be greater than 16 characters")
  .matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).+$/,
    "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one special character"
  )

});

export const resetPasswordDirectlySchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required("Old Password is required")
    .min(8, "Old Password should be at least 8 characters long")
    .max(16, "Old Password should not be greater than 16 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).+$/,
      "Old Password format is incorrect"
    ),
  newPassword: Yup.string()
    .required("New Password is required")
    .min(8, "New Password should be at least 8 characters long")
    .max(16, "New Password should not be greater than 16 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).+$/,
      "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one special character"
    )
    .test('passwords-match', 'New password should be different from old password', function (value) {
      return this.parent.oldPassword !== value;
    }),
  confirmPassword: Yup.string()
  .required("Confirm Password is required")
  .min(8, "Confirm Password should be at least 8 characters long")
  .max(16, "Confirm Password should not be greater than 16 characters")
  .matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).+$/,
    "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one special character"
  )
  .test('passwords-match', 'New password and confirm password should match', function (value) {
    return this.parent.newPassword === value;
  }),
});

