import { Snackbar } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const Notification = (props) => {
  const { value, display, onClose } = props;
  const errorDetails = useSelector((state) => state?.error);

  return (
    <Snackbar
      ContentProps={{
        sx: {
          marginTop:"40px",
          background: errorDetails?.errorStatus === "failure" ? "red" : "#212b44 ",
          fontWeight: 600,
          color:  errorDetails?.errorStatus === "failure" ?"#ffff":"#ffff",
        }
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={display}
      message={value}
      autoHideDuration={1000}
      onClose={onClose}
    />


  );
};
export default Notification