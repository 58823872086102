import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, ErrorField, Input } from "../components";
import { mutationAPI } from "../api/services";
import { ADMIN_FORGOT_PASSWORD } from "../api/constants";
import Notification from "../components/notification";
import { emailSchema } from "../utils/schema";
import Logo from "../assets/reffyy_full_navy.png";
import { useValidationErrors } from "../utils/useErrors";
import { errorAvailable, errorClose, errorStatus } from "../redux/errorSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiMessage, title } from "../utils/constants";
const ForgotPassword = () => {
  const [formData, setFormData] = useState({ email: "" });
  const dispatch = useDispatch();
  const [errors, setErrors, handleValidationErrors] = useValidationErrors();
  const errorDetails = useSelector((state) => state?.error);
  const [isDisable, setIsDisable] = useState(false);
  const handleLogin = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isDisable) return;
    setIsDisable(true);
    try {
      await emailSchema.validate(
        { email: formData.email },
        { abortEarly: false }
      );
      const response = await mutationAPI(ADMIN_FORGOT_PASSWORD, "POST", {
        email: formData.email.trim(),
      });

      if (response.status === 200) {
        dispatch(
          errorAvailable(apiMessage.EMAIL_SENT)
        );
        dispatch(errorStatus(apiMessage.SUCCESS))

      } else if (response.status === 400) {
        dispatch(errorAvailable(response?.err?.response?.data?.message));
        dispatch(errorStatus(apiMessage.FAILURE))
      } else {
        dispatch(errorAvailable(title?.wrong));
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        handleValidationErrors(error);
      }
    } finally {
      setIsDisable(false);
    }
  };

  return (
    <>
      <div className="lui-auth-wrapper">
        <div className="lui-auth-container">
          <div className="lui-auth-banner">
            <img src={Logo} alt="logo" />
          </div>
          <div className="lui-auth-heading">
            <h5>Forgot Password!</h5>
            <p>Please enter your registered email</p>
          </div>
          <div className="lui-auth-form">
            <form autoComplete="off">

              <Input
                type="email"
                label="Email"
                name="Email"
                value={formData?.email}
                onChange={(e) => {
                  setFormData({ ...formData, email: e?.target?.value })
                  setErrors({})
                }
                }
              />

              {errors && (
                <ErrorField error={errors?.email} />
              )}
              <Button
                disabled={isDisable}
                variant="primary"
                onClick={handleLogin}
                className="login-btn"
              >
                Click Here
              </Button>
            </form>
          </div>
          <div className="lui-auth-form-actions">
            <p>
              <Link to={"/login"}>Back to Login Page</Link>
            </p>
          </div>
        </div>
      </div>
      <Notification
        value={errorDetails?.errorName}
        display={errorDetails?.errorDisplay}
        onClose={() => {
          dispatch(errorClose(false));
        }}
      />
    </>
  );
};

export default ForgotPassword;
