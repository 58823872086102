import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import PersonIcon from "@mui/icons-material/Person";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";

import Icons from "../components/icons";




const handleIconClick = () => {
  document.getElementById("fileInput").click();
};
const handleVideoClick = () => {
  document.getElementById("fileVideoInput").click();
};
const style = { cursor: "pointer" };
export const getIcon = (type, state, fnc) => {
  console.log(type, "type");
  switch (type) {
    case "Date":
      return <CalendarMonthIcon style={style} />;
    case "Search":
      return <Icons name="search" style={style} />;
    case "Image":
      return (
        <>
          <CameraAltIcon onClick={handleIconClick} style={style} />
          <input
            hidden
            type="file"
            id="fileInput"
            onChange={fnc}
            accept="image/*"
          />
        </>
      );
    case "Video Upload":
      return (
        <>
          {" "}
          <VideoCameraBackIcon onClick={handleVideoClick} style={style} />{" "}
          <input
            hidden
            type="file"
            id="fileVideoInput"
            onChange={fnc}
            accept="video/*"
          />
        </>
      );
    case "Password":
    case "New Password":
    case "Confirm Password":
    case "Old Password":
      case "Current Password":

      return (
        <IconButton onClick={fnc} edge="end">
          {state ? (
            <VisibilityIcon style={style} />
          ) : (
            <VisibilityOffIcon style={style} />
          )}
        </IconButton>
      );
    case "State":
      return <LocationSearchingIcon style={style} />;
    case "Name":
      return <PersonIcon style={style} />;
    default:
      return null;
  }
};

export const getLabel = (label) => {
  const capitalizeFirstLetter = (str) => 
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  switch (label && label.toUpperCase()) {
    case "USERNAME":
      return "Name";
    case "REGISTRATION DATE":
      return "Date";
    case "EMAIL":
      return "Email";
    case "USERTYPE":
      return "Type";
    case "MODE OF SIGNUP":
      return "Mode";
    case "STATUS":
      return "Status";
    case "PASSWORD" :
      return 'Password'
    case "SEARCH" :
      return "Search"
       case "VALUE" :
      return "Title"
    default:
      return label ? capitalizeFirstLetter(label) : label;;
  }
};

export const createYearArray = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 1950;
  const yearArray = [];

  for (let year = startYear; year <= currentYear; year++) {
    yearArray.push(year);
  }

  return yearArray.sort((a, b) => b - a);
};

export const titleSplice = (value, max = 150) => {
  return value?.length > max ? value.substring(0, max) + "..." : value;
};



export function formatToReadableDate(value) {
  // Create a Date object from the ISO string
  const date = new Date(value);

  // Array of month names
  const monthNames = [
    "Jan", "Feb", "March", "April", "May", "June",
    "July", "Aug", "Sept", "Oct", "Nov", "Dec"
  ];

  // Extract the parts of the date
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Format and return the string
  return `${month} ${day}, ${year}`;
}

// Example usage
const formattedDate = formatToReadableDate("2024-10-29T07:51:46.522Z");
console.log(formattedDate); // Output: October 29, 2024


   // Define a mapping between pathnames and moduleConfig keys
export    const pathToConfigMap = {
    "/content-management/experience": "experienceManagement",
    "/content-management/goal": "goalManagement",
    "/content-management/interest": "interestManagement",
    "/content-management/industry": "industryManagement",
    "/content-management/user": "userTypeManagement",
    "/user-management" :"userManagement",
    "/reviews" :"reviews",
    "/verification":"expertManagement",
    "/endorsement":"endorsementManagement"



  };

  export const helpers = {
    userContentHeaders : ['Title' ,'Description','Actions'],
    experienceContentHeaders:["Title","Description","Actions"],
    industryContentHeaders:["Title","Media","Count","Action"],
    userListHeaders:["Name","Date","Email","Type","Mode","Status", "Actions"],
    ExpertHeaders:["Name","userType","Email", "Actions"],
    GoalHeaders:["Title","Actions"],
    interestHeaders : ["Interests" ,"Date","Actions"],
    reviewsHeaders:[ "Name" ,"Review","Date", "business reviewed", "Actions"],
    endorseHeaders:[ "Name" ,"Email", "Type", "Actions"]

}

export function capitalizeFirstLetter(word) {
  if (!word || typeof word !== "string") {
      return "";
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function getLastCompleteMonthRange() {
  const now = new Date();
  const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);

  return {
    startDate: startOfLastMonth, // Date object
    endDate: endOfLastMonth,     // Date object
  };
}



