import React, { useCallback } from "react"
import { CustomizedTables, Input, Pagination, Skeleton } from "../components";
import { useEffect, useState } from "react";
import { mutationAPI } from "../api/services";
import { useDispatch, useSelector } from "react-redux";
import { isDataLoading, isSkeletonLoading } from "../redux/dataSlice";
import { AccountCircleIcon, VisibilityIcon } from "../utils/constants";
import { helpers } from "../utils/helper";
import { ADMIN_EXPERT_VERIFICATION_GETLIST } from "../api/constants";
import { Box, Tab, Tabs } from "@mui/material";

const ExpertVerification = () => {
  const [data, setData] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState('');

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    console.log({ newValue })
    setValue(newValue);
    setSearchVal("")
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [callApi, setCallapi] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { skeletonLoading } = useSelector((state) => state?.data);

  console.log({ callApi })
  const dispatch = useDispatch();

  const fetchUserList = async () => {
    try {
      // Show skeleton loading only on initial load
      if (isInitialLoad) {
        dispatch(isSkeletonLoading(true));
      }
      // Show data loading when rowsPerPage changes
      else if (!callApi) {
        dispatch(isDataLoading(true));
      }

      const response = await mutationAPI(
        `${ADMIN_EXPERT_VERIFICATION_GETLIST}?page=${page}&limit=${rowsPerPage}&filter=${value}`,
        "GET"
      );

      if (response?.status) {
        const formattedData = response?.data?.data?.map((item) => ({
          _id: item?._id,
          userName: item?.userName,
          userType: item?.userType,
          email: item?.email,
          industries: item?.industries,
          interests: item?.interests,
          isActionPerformed: item?.isActionPerformed,
          isCertiApproved: item?.isCertiApproved,
          docs: item?.docs,
          actions: [
            ...(!item?.isActionPerformed || (!item?.isCertiApproved && !item?.isActionPerformed)
              ? [{ key: "EDIT", icon: <AccountCircleIcon />, text: "EDIT" }]
              : []
            ),
            { key: "VIEW", icon: <VisibilityIcon />, text: "VIEW" }
          ].filter(Boolean),
        }));

        setData(formattedData);
      }
    } catch (error) {
      console.error('Error fetching verification list:', error);
    } finally {
      // Clear both loading states
      dispatch(isSkeletonLoading(false));
      dispatch(isDataLoading(false));
      setIsInitialLoad(false);


    }
  }; // Removed isInitialLoad to prevent double calls


  useEffect(() => {
    fetchUserList();
  }, [rowsPerPage, page, callApi, value]);
  // Reset isInitialLoad on unmount
  useEffect(() => {
    return () => {
      setIsInitialLoad(true);
    };
  }, []);
  const handleSearchChange = (e) => {
    const searchvalue = e.target.value;
    const trimmedValue = searchvalue.trim(); // Trim for checks and final API call
    const hasValidChars = /\S/.test(trimmedValue); // Ensure non-whitespace characters
    setSearchVal(searchvalue);


    // Track if space was pressed
    const isSpace = e.keyCode === 32 || e.key === 'Space' || e.key === ' ';
    // Skip processing if there's no valid content
    if (!hasValidChars && isSpace) {
 
      return;
    }

    // Clear the previous debounce timer
    if (debouncedQuery) clearTimeout(debouncedQuery);

    // Set a new debounce timer
    const timer = setTimeout(async () => {
      try {
        dispatch(isDataLoading(true));

        const response = await mutationAPI(
          `${ADMIN_EXPERT_VERIFICATION_GETLIST}?search=${searchvalue}&page=${page}&limit=${rowsPerPage}&filter=${value}`,
          "GET"
        );

        if (response?.status) {
          const allData = response?.data?.data?.map((item) => ({
            _id: item?._id,
            userName: item?.userName,
            userType: item?.userType,
            email: item?.email,
            industries: item?.industries,
            interests: item?.interests,
            isActionPerformed: item?.isActionPerformed,
            isCertiApproved: item?.isCertiApproved,
            docs: item?.docs,
            actions: [
              item?.isActionPerformed && item?.isCertiApproved
                ? ""
                : { key: "EDIT", icon: <AccountCircleIcon />, text: "EDIT" },
              { key: "VIEW", icon: <VisibilityIcon />, text: "VIEW" },
            ],
          }));

          setData(allData);
        }
      } catch (error) {
        console.error("Error fetching expert verification data:", error);
      } finally {
        dispatch(isDataLoading(false));
      }
    }, 500);

    setDebouncedQuery(timer);
  };



  return (
    <div className="expert-management__comp">

      <Box
        className="expert-management_box"
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          {

            isInitialLoad ?
              <div className="expert-skeleton-css">
                <Skeleton.Card value={3} width={"112px"} height={"47px"} borderRadius={"4px"} className={"inputRight-skeleton"} />          </div>


              :
              <Tabs className="custom-tabs" // Apply the custom class

                value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="PENDING" disableRipple />
                <Tab label="APPROVED" disableRipple />
                <Tab label="DECLINED" disableRipple />
              </Tabs>}
        </Box>


        {isInitialLoad ?
          <Skeleton.Card value={1} width={"432px"} height={"56px"} borderRadius={"4px"} className={"inputRight-skeleton"} />
          : <div className="input_div">
            <Input
              className="input"
              type="text"
              label="Search"
              value={searchVal}
              onChange={handleSearchChange}
            /></div>}

      </Box>

      <CustomizedTables
        componentData={data || []}
        componentHeaders={helpers.ExpertHeaders}
        page={1}
        searchVal={searchVal}
        setPage={setPage}
        rowsPerPage={2}
        setData={setData}
        count={data?.length}
        fetchList={fetchUserList}
        height={"calc(100vh - 376px)"}
        setCallapi={setCallapi}

      />
      {!data?.length && searchVal === "" && !skeletonLoading ? "" :
        <Pagination rowsPerPage={rowsPerPage} page={page} setRowsPerPage={setRowsPerPage} setPage={setPage} />}

    </div>
  );
}

export default ExpertVerification