import React, { useEffect, useState } from "react"
import { isDataLoading, isSkeletonLoading } from "../redux/dataSlice";
import { mutationAPI } from "../api/services";
import { ADMIN_REVIEW_GET } from "../api/constants";
import { useDispatch } from "react-redux";
import { CustomizedTables, Input, Pagination, Skeleton } from "../components";
import { formatToReadableDate, helpers } from "../utils/helper";
import { DeleteOutline, VisibilityIcon } from "../utils/constants";
const ReviewManagement = () => {
  const [data, setData] = useState([])
  const dispatch = useDispatch()

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [page, setPage] = useState(0);
  const [keyData, SetKeyData] = useState({ title: "", years: "" })
  const [isLoaded, setIsLoaded] = useState(false); // Track preloading
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchVal, setSearchVal] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState('');

  
  //  if (!data || !isLoaded) {
  //   return <Skeleton.Table />; // Show spinner while preloading
  // }
  const preloadAllImages = (data) => {
    data.forEach((record) => {
      record.media.forEach((mediaItem) => {
        const img = new Image();
        img.src = mediaItem.s3Url;
      });
    });
    setIsLoaded(true); // Mark as loaded after preloading
  };

 
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchVal(value);
    const trimmedValue = value.trim(); // Trim for checks and final API call
    const hasValidChars = /\S/.test(trimmedValue); // Ensure non-whitespace characters
  
   // Track if space was pressed
   const isSpace = e.keyCode === 32 || e.key === 'Space' || e.key === ' ';
   // Skip processing if there's no valid content
   if (!hasValidChars && isSpace) {

     return;
   }
    // Clear the previous debounce timer
    if (debouncedQuery) clearTimeout(debouncedQuery);

    // Set a new debounce timer
    const timer = setTimeout(async () => {
      try {
        dispatch(isDataLoading(true));
        const response = await mutationAPI(
          `${ADMIN_REVIEW_GET}?search=${value}&skipVal=${page}&limitVal=${rowsPerPage}`,
          "GET"
        );

        if (response?.status) {
          preloadAllImages(response?.data?.data);

          setData(
            response?.data?.data?.map((item, i) => ({
              _id: item?._id,
              name: item?.userName,
              review:item?.review,
              date: formatToReadableDate(item?.createdAt),
              reviewed: item?.businessUserName,
              review: item?.review,
              media: Array.isArray(item?.media)
                ? item?.media?.map((m, index) =>
                    m.fileType === "video" ? (
                      <video
                        key={index}
                        style={{
                          objectFit: "cover",
                          width: "300px",
                          height: "200px",
                        }}
                        controls
                        src={m.s3Url}
                      >
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        key={index}
                        style={{
                          objectFit: "cover",
                          width: "300px",
                          height: "200px",
                        }}
                        src={m.s3Url}
                        alt={m.fileName || "Media"}
                      />
                    )
                  )
                : null,
              actions: [
                {
                  key: "VIEW",
                  icon: <VisibilityIcon />,
                  text: "VIEW",
                },
                {
                  key: "DELETE",
                  icon: <DeleteOutline />,
                  text: "DELETE",
                },
              ],
            }))
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(isDataLoading(false));
      }
    }, 500);

    setDebouncedQuery(timer);
  };
 
 // Memoize fetchData to prevent unnecessary recreations
 const fetchData = React.useCallback(async () => {
  try {
    // Show skeleton loading only on first load or rowsPerPage change
    if (isFirstLoad) {
      dispatch(isSkeletonLoading(true));
    } else {
      dispatch(isDataLoading(true));
    }   


        const response = await mutationAPI(
          `${ADMIN_REVIEW_GET}?skipVal=${page}&limitVal=${rowsPerPage}`,
          "GET"
        );

        if (response?.status) {
          preloadAllImages(response?.data?.data); // Preload images


          setData(response?.data?.data?.map((item, i) => ({
            _id: item?._id,
            name: item?.userName,
            review:item?.review,
            date: formatToReadableDate(item?.createdAt),
            reviewd: item?.businessUserName,
            media: Array.isArray(item?.media)
              ? item?.media?.map((m, index) => (
                m.fileType === "video" ? (
                  <video
                    key={index}
                    style={{
                      objectFit: "cover",
                      width: "300px",
                      height: "200px",
                      objectFit: "contain",
                    }}
                    controls
                    src={m.s3Url}
                  >
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    key={index}
                    style={{
                      objectFit: "cover",
                      width: "300px",
                      height: "200px",
                      objectFit: "contain",
                      // background:"grey  "
                    }}
                    src={m.s3Url}
                    alt={m.fileName || "Media"}
                  />
                )
              ))
              : null

            , actions: [
              {
                key: "VIEW", icon:
                  <VisibilityIcon />,text:"VIEW"
              },

              { key: "DELETE", icon: <DeleteOutline />,text:"DELETE" }

            ]

          })) || []);
        }
      } catch (error) {
        // Handle any errors (e.g., log or display an error message)
      } finally {
        // Clear both loading states
        dispatch(isSkeletonLoading(false));
        dispatch(isDataLoading(false));
        setIsFirstLoad(false);
      }
    }, [page, rowsPerPage]);
    useEffect(() => {
      fetchData();
    }, [fetchData]);
  console.log({ data })
  return (
   <div className='review-management__comp'>
     {isFirstLoad ?
     <div style={{  margin:"1rem 0rem",
      float: "right"}} className='skeleton'>
        <Skeleton.Card value={1} width={"432px"} height={"56px"} borderRadius={"4px"} className={"inputRight-skeleton"} /></div>
        :
      <Input
          className="input"
          type="text"
          label="Search"
          value={searchVal}
          onChange={handleSearchChange}
        /> }
      <CustomizedTables
        componentData={data || []}
        componentHeaders={helpers.reviewsHeaders}
        page={1}
        searchVal={searchVal}
        // setPage={setPage}
        rowsPerPage={2}
        setData={setData}
        fetchList={fetchData}
        height= {"calc(100vh - 320px)"}

      />
                  <Pagination rowsPerPage={rowsPerPage} page={page} setRowsPerPage={setRowsPerPage} setPage={setPage}  />

    </div>
  )
}

export default ReviewManagement