import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { mutationAPI } from "../../api/services";
import { useDispatch } from "react-redux";
import { isDataLoading } from "../../redux/dataSlice";
import Button from "../button";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { errorAvailable, errorStatus } from "../../redux/errorSlice";


export default function DeleteModal(props) {
  const { isOpen, close, isDisable,data,setData,DeleteUrl,GetUrl ,ApiData} = props;
  const [deleted, setDeleted]=React.useState(false)
  const dispatch = useDispatch();

  const submitHandler=async()=>{
    setDeleted(true);
    setTimeout(()=>{
      setDeleted(false)
    },3000) 
    try {
  dispatch(isDataLoading(true))
    const response = await mutationAPI(ApiData?.endPoint, ApiData?.method, {_id:data?._id});
    dispatch(errorAvailable("Deleted successfully"
      ));
    dispatch(errorStatus("success"))
    ApiData?.getApi()

   const res = await mutationAPI(ApiData?.getApi, "GET",{});
  //  setData(res?.data?.data)
   close()   
  } catch (error) {
      console.log({error})
      dispatch(errorAvailable("An error occurred while submitting the form. Please try again."));
      dispatch(errorStatus("failure"))
  }finally{
    dispatch(isDataLoading(false))
    close()  
  }
 }
  return (
    <React.Fragment>
      <Dialog
    className="dialog_Del"
        open={isOpen}
        onClose={close}
        fullWidth
        maxWidth="sm"
        disableBackdropClick={true}
      >
        <DialogTitle className="dialog_title_delete">     Are you sure you want to delete?

      
        </DialogTitle>
   
        <DialogActions className="dialog_action_delete">
          <Button
            className="submit-button"
            onClick={submitHandler}
            disabled={isDisable}
            loading={deleted}
          >
            <DoneIcon />
            </Button>
          <Button
                      onClick={() => close()}
                      className="close-button"

            

          >
                        <CloseIcon />

          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
