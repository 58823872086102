import { configureStore } from '@reduxjs/toolkit';
import navigationSlice from './navigationSlice';
import dataSlice from './dataSlice';
import errorSlice from './errorSlice';
import focusSlice from './focusSlice'

export const store = configureStore({
  reducer: {
    navigation: navigationSlice,
    data:dataSlice,
    error:errorSlice,
    focus:focusSlice

  }
})