import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { ADMIN_CONTENT_EDIT_EXPERIENCE, ADMIN_CHANGE_STATUS, ADMIN_CONTENT_EDIT_GOAL, ADMIN_CONTENT_EDIT_INTEREST, ADMIN_CONTENT_EDIT_INDUSTRY, ADMIN_CONTENT_EDIT_USERTYPE, ADMIN_CONTENT_GET_INDUSTRY, ADMIN_CONTENT_GET_USERTYPE, ADMIN_CONTENT_GET_EXPERIENCE, ADMIN_CONTENT_GET_GOAL, ADMIN_CONTENT_GET_INTEREST, ADMIN_USER_LISTS, ADMIN_CONTENT_DELETE_USERTYPE, ADMIN_CONTENT_DELETE_EXPERIENCE, ADMIN_CONTENT_DELETE_GOAL, ADMIN_CONTENT_DELETE_INDUSTRY, ADMIN_CONTENT_DELETE_INTEREST, ADMIN_USER_DELETE_LIST, ADMIN_REVIEW_DELETE, ADMIN_ENDORSEMENT_EDIT, ADMIN_ENDORSEMENT_GET, ADMIN_ENDORSEMENT_DELETE, ADMIN_EXPERT_VERIFICATION_UPDATE } from "../../api/constants";
import { Skeleton, NoData, ToolTip, PopUp, Pagination, FloatPopUp, Cards, Loader } from "../index";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import DeleteModal from "../modal/deleteModal";
import CustomModal from "../modal/CustomModal";
import { isDataLoading } from "../../redux/dataSlice";
import { mutationAPI } from "../../api/services";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter, pathToConfigMap } from "../../utils/helper";
import { errorAvailable, errorStatus } from "../../redux/errorSlice";


export default function CustomizedTables({
  componentData,
  componentHeaders,
  searchVal,
  count,
  fetchList,
  setApiCall,
  height,
  setData,
  setCallapi

}) {
  const location = useLocation();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const { skeletonLoading } = useSelector((state) => state?.data);
  const [rowData, setRowData] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenDelete, setIsOpenDelete] = React.useState(false);
  const [viewCard, setViewCard] = React.useState(null);
  const dispatch = useDispatch();
  const [userStatus, setUserStatus] = useState("Active")
  const [review, setReview] = useState(false)
  const [verify, setVerify] = useState(false)

  const isDataFocused = useSelector((state) => state.focus.isdataFocus);

  console.log({ isDataFocused })
  // Find the correct config based on the pathname
  const configKey = pathToConfigMap[pathname];

  const userStatusUpdate = rowData?.status === "Active" ? "Inactive" : "Active"
  //props data for edit modal
  const moduleConfig = {
    userTypeManagement: {
      setResponseData: setRowData,
      dataResponse: rowData,
      isEdit: true,
      Close: setIsOpen,

      Open: isOpen,
      ApiData: {
        endPoint: ADMIN_CONTENT_EDIT_USERTYPE,
        method: "PATCH",
        getApi: fetchList

      },
      defaultResponse: {
        heading: "",
        description: "",
      },
      title: "UserType"
    },
    experienceManagement: {
      setResponseData: setRowData,
      dataResponse: rowData,
      isEdit: true,
      Close: setIsOpen,

      Open: isOpen,
      ApiData: {
        endPoint: ADMIN_CONTENT_EDIT_EXPERIENCE,
        method: "PATCH",
        getApi: fetchList

      },
      defaultResponse: {
        title: "",
        years: "",
      },
      title: " Experience"
    },
    goalManagement: {
      setResponseData: setRowData,
      dataResponse: rowData,
      isEdit: true,
      Close: setIsOpen,

      Open: isOpen,
      ApiData: {
        endPoint: ADMIN_CONTENT_EDIT_GOAL,
        method: "PATCH",
        getApi: fetchList
      },
      defaultResponse: {
        title: "",
      },
      title: " Goal"

    },
    industryManagement: {
      setResponseData: setRowData,
      dataResponse: rowData,
      isEdit: true,
      Close: setIsOpen,

      Open: isOpen,
      ApiData: {
        endPoint: ADMIN_CONTENT_EDIT_INDUSTRY,
        method: "PATCH",
        getApi: fetchList
      },
      defaultResponse: {
        title: "",
        url: "",

      },
      title: " Industry"

    }, interestManagement: {
      setResponseData: setRowData,
      dataResponse: rowData,
      isEdit: true,
      Close: setIsOpen,

      Open: isOpen,
      ApiData: {
        endPoint: ADMIN_CONTENT_EDIT_INTEREST,
        method: "PATCH",
        getApi: fetchList
      },
      defaultResponse: {
        value: "",
      },
      title: " Interest"

    }, userManagement: {
      setResponseData: setRowData,
      dataResponse: rowData,
      isEdit: true,
      Close: setIsOpen,

      Open: isOpen,
      ApiData: {
        endPoint: ADMIN_CHANGE_STATUS,
        method: "PATCH",
        getApi: fetchList

      },
      defaultResponse: {
        username: "",
      },
      title: " User"

    }, expertManagement: {
      setResponseData: setRowData,
      dataResponse: rowData,
      isEdit: true,
      Close: setIsOpen,

      Open: isOpen,
      ApiData: {
        endPoint: "",
        method: "PATCH",
        getApi: fetchList

      },
      defaultResponse: {
        title: "",
      },
      title: " User",
      modalEdit: true,

    },
    endorsementManagement: {
      setResponseData: setRowData,
      dataResponse: rowData,
      isEdit: true,
      Close: setIsOpen,

      Open: isOpen,
      ApiData: {
        endPoint: ADMIN_ENDORSEMENT_EDIT,
        method: "PATCH",
        getApi: fetchList

      },
      defaultResponse: {
        username: "",
        email: "", password: ""
      },
      title: " Endorsement"
    },
  }

  //props data for delete Modal
  const moduleDeleteConfig = {
    userTypeManagement: {
      isOpen: isOpenDelete,
      close: setIsOpenDelete,
      data: rowData,
      ApiData: {
        endPoint: ADMIN_CONTENT_DELETE_USERTYPE,
        method: "DELETE",
        getApi: fetchList

      },
      title: "UserType"
    },
    experienceManagement: {
      isOpen: isOpenDelete,
      close: setIsOpenDelete,
      data: rowData,

      ApiData: {
        endPoint: ADMIN_CONTENT_DELETE_EXPERIENCE,
        method: "DELETE",
        getApi: fetchList

      },
      title: " Experience"
    },
    goalManagement: {
      isOpen: isOpenDelete,
      close: setIsOpenDelete,
      data: rowData,

      ApiData: {
        endPoint: ADMIN_CONTENT_DELETE_GOAL,
        method: "DELETE",
        getApi: fetchList
      },

      title: " Goal"

    },
    industryManagement: {
      isOpen: isOpenDelete,
      close: setIsOpenDelete,
      data: rowData,

      ApiData: {
        endPoint: ADMIN_CONTENT_DELETE_INDUSTRY,
        method: "DELETE",
        getApi: fetchList
      },
      title: " Industry"

    }, interestManagement: {
      isOpen: isOpenDelete,
      close: setIsOpenDelete,
      data: rowData,
      setData: setData,
      ApiData: {
        endPoint: ADMIN_CONTENT_DELETE_INTEREST,
        method: "DELETE",
        getApi: fetchList
      },
      title: " Interest"

    }, userManagement: {
      isOpen: isOpenDelete,
      close: setIsOpenDelete,
      data: rowData,

      ApiData: {
        endPoint: ADMIN_USER_DELETE_LIST,

        method: "DELETE",
        getApi: fetchList
      },
      title: " User"

    },
    reviews: {
      isOpen: isOpenDelete,
      close: setIsOpenDelete,
      data: rowData,

      ApiData: {
        endPoint: ADMIN_REVIEW_DELETE,
        method: "DELETE",
        getApi: fetchList
      },
      title: " Review"

    },
    endorsementManagement: {
      isOpen: isOpenDelete,
      close: setIsOpenDelete,
      data: rowData,

      ApiData: {
        endPoint: ADMIN_ENDORSEMENT_DELETE,
        method: "DELETE",
        getApi: fetchList

      },
      title: " Endorsement"
    },
  }
  const selectedConfig = moduleConfig[configKey];
  const selectedDelete = moduleDeleteConfig[configKey]
  // handling icon here
  const handleIcon = (key, value, e) => {
    setRowData(value)
    console.log({ value }, configKey)
    console.log({ key })
    if (key === "EDIT") {
      if (pathname === "/verification") {
        setOpen(true);
        setVerify(true)

      } else {
        setIsOpen(true);

      }
    } else if (key === "DELETE") {
      setIsOpenDelete(true);
    }
    else if (key === "ACTIVE" || key === "INACTIVE") {
      setOpen(true);
    } else if (key === "VIEW") {
      if (configKey === "reviews") {
        setReview(true)
      } if (pathname === "/verification") {
        setIsOpen(true)
      }
      setRowData(value)
      setViewCard(e.target)
    }
  }

  console.log("count", componentData?.length, { searchVal },componentData   )
  //conditions for loading state
  if (skeletonLoading) return <Skeleton.Table />

  if (count?.length === 0 && !searchVal && pathname !== "/verification") return <Skeleton.Table />;
  if (!componentData?.length && searchVal === "" && !skeletonLoading) return <NoData />;
  if (count?.length === 0 && !searchVal && pathname === "/verification") return <NoData />;
  if (!componentData?.length && searchVal !== "") return <NoData />;




  const modalPropsExpert = {
    expert: true,
    isOpen: open,
    close: () => setOpen(!open),
    submitHandler: async (status) => {
      dispatch(isDataLoading(true));

      try {

        const response = await mutationAPI(`${ADMIN_EXPERT_VERIFICATION_UPDATE}`, "POST", {
          "email": rowData?.email,
          "isCertiApproved": status,
          "isActionPerformed": true
        });
        dispatch(errorAvailable("Success"));
        dispatch(errorStatus("expert user approved successfully"))
        if (response?.status == 200) {
          dispatch(isDataLoading(false));
          fetchList()
          return setApiCall(true)

        }

      } catch (error) {

      } finally {
        setOpen(false);
      }
    },
    title: `Are you sure, you want to Update the user?`,
    isDisable: false,
    children: null,
  };
  const modalProps = {
    expert: false,

    isOpen: open,
    close: () => setOpen(!open),
    submitHandler: async () => {
      dispatch(isDataLoading(true));

      try {
        const payload = {
          _id: rowData?._id,
          isActive: rowData?.status === "Active" ? false : true
        }
        console.log(rowData?.status, { rowData })
        const response = await mutationAPI(`${ADMIN_CHANGE_STATUS}?_id=${payload?._id}`, 'PATCH', payload)
        dispatch(errorAvailable("User updated successfully"));
        dispatch(errorStatus("success"))
        // const res = await handleUserStatus(payload)
        if (response?.status == 200) {
          dispatch(isDataLoading(false));

          return setApiCall(true) && setOpen(false);

        }

      } catch (error) {

      } finally {
        setOpen(false);
      }
    },
    title: `Are you sure, you want to ${userStatusUpdate} the user?`,
    isDisable: false,
    children: null,
  };
  // Function to truncate text
  const truncateText = (text) => {
    return text?.length > 100 ? text?.split('').slice(0, 100).join('') + "..." : text;
  }

  console.log({ rowData })
  const excludeKeys = ["_id", "media", "industries", "interests", "docs", "isCertiApproved", "isActionPerformed"];
  const updatedmodalProps = pathname === "/user-management" ? modalProps : modalPropsExpert
  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        className="table_container"
        // style={{ height: `${height}` }}
        style={{ maxHeight: "fit-content" }}
      >
        <Table aria-label="customized table" stickyHeader>

          <TableHead >


            <TableRow className="table_header">
              {componentHeaders?.map((item) => {
                return <TableCell >{item}</TableCell>

              })}
            </TableRow>
          </TableHead>
          <TableBody>

            {
            // !componentData?.length && isDataFocused && !searchVal ?
            //   <TableRow >
            //     <TableCell
            //       sx={{ textAlign: "center", height: "200px" }}
            //       colSpan={componentHeaders?.length} // Adjust colSpan to span across all columns

            //     >

            //     </TableCell>
            //   </TableRow> :
              componentData?.map((row, i) => (
                <TableRow key={i}>
                  {Object.keys(row)
                    .filter((key) => !excludeKeys.includes(key)).map((key) => (
                      <TableCell key={key}>

                        {key === "actions" && Array.isArray(row[key]) ? ( // Check if it's an array and if the key is 'actions'
                          <Box className="icon_wrapper">

                            {row[key]?.map((action, index) => (
                              <>
                                <ToolTip
                                  key={index}
                                  element={
                                    <div
                                      onClick={(e) => {
                                        handleIcon(action?.key, row, e);
                                        setRowData(row);
                                      }}
                                    >
                                      {action?.icon}
                                    </div>
                                  }
                                  isToggle={true}
                                  content={action?.text}
                                />
                              </>
                            ))}
                          </Box>
                        ) : (
                          <>
                            {key === "description" || key === "review" && row[key]?.length > 100 ? (
                              <ToolTip
                                isToggle={true}
                                content={row[key]}
                                element={
                                  <div>
                                    {truncateText(row[key]) || "N/A"}
                                  </div>
                                }
                              />
                            ) : (

                              key === "url" ?
                                <img src={row?.[key]} className="zoom-css" />

                                :
                                key === "status" ?
                                  <div className={row[key] === "Active" ? "active-css" : "inactive-css"}>{row[key]}</div>

                                  :
                                  key === "title" || key === "username" || key === "value" ?
                                    <div>
                                      {capitalizeFirstLetter(row[key]) || "N/A"}
                                    </div> :
                                    <div>
                                      {row[key] || "N/A"}
                                    </div>
                            )}
                          </>
                        )}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            <FloatPopUp

              children={
                <div className="table_card">
                  <Cards
                    setData={setData}
                    data={rowData}
                    review={review}
                    fetchList={fetchList}
                    setCallapi={setCallapi}
                    onClose={() => {
                      setViewCard(null);
                    }}
                  />
                </div>
              }
              anchor={viewCard}
              placement="right"
              onClose={() => {
                setViewCard(null);
              }}
            />
          </TableBody>
        </Table>
      </TableContainer>


      {/* ACtive Inactive Modal */}
      <PopUp {...updatedmodalProps} />

      {/* Custom Delete Modal */}

      {
        isOpenDelete && (
          <DeleteModal

            {...selectedDelete}

          />
        )
      }
      {/*  Custom Edit or Create Modal */}

      {
        isOpen && selectedConfig && (
          <CustomModal
            {...selectedConfig}
            // verify={verify}
            rowData={rowData}
          />
        )
      }

      {/* Pagination */}


    </React.Fragment>
  );
}
