import React, { useState } from "react";

const Index = ({ value, name, onChange, className, label }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  return (
    <div className={`lui-input-wrapper`}>
      {label && (
        <label
          className={`lui-input-label ${ isFocused ? "" : ""} ${value  ? "value-active" : "value-active"} `}
        >
          {label}
        </label>
      )}

      <div className={`lui-input-container ${isFocused ? "" : ""} `}>
        <textarea
          className={`lui-input text-area-custom ${className}`}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={onChange}
          value={value}
          name={name}
        />
      </div>
    </div>
  );
};

export default Index;

